import decodeJwt from "jwt-decode";

const auth = ({ token, centrifugoToken, refreshToken }) => {
  const decodedToken = decodeJwt(token);

  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("roles", decodedToken.roles);
  localStorage.setItem("centrifugoToken", centrifugoToken);
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("roles");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("centrifugoToken");
};

export default ({ refreshTokenUrl, onAuthenticated }) => ({
  logout: () => {
    logout();
    return Promise.resolve();
  },
  getPermissions: () => {
    const roles = localStorage.getItem("roles");
    return roles ? Promise.resolve(roles.split(",")) : Promise.reject(new Error("There are no permissions"));
  },
  handleCallback: async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const centrifugoToken = params.get("centrifugoToken");
    const refreshToken = params.get("refreshToken");

    if (!!token && !!centrifugoToken && !!refreshToken) {
      auth({ token, centrifugoToken, refreshToken });
      if (onAuthenticated) {
        onAuthenticated();
      }
    } else {
      throw new Error("Authorization error");
    }
  },

  checkAuth: async () => {
    const token = localStorage.getItem("token");
    const redirectUrl = window.location.pathname;

    if (
      redirectUrl &&
      !redirectUrl.startsWith("/login") &&
      !redirectUrl.startsWith("/check_auth")
    ) {
      localStorage.setItem("redirectUrl", redirectUrl);
    }

    const resolveOptions = () => {
      const url = localStorage.getItem("redirectUrl");
      if (url) {
        localStorage.removeItem("redirectUrl");
        return { redirectTo: url };
      }
    };
    if (token) {
      const decodedToken = decodeJwt(token);

      if (!decodedToken) {
        logout();
        return Promise.reject();
      } else if (decodedToken.exp > new Date().getTime() / 1000) {
        console.log("auth ok, token not expired");
        return Promise.resolve(resolveOptions());
      } else {
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          return fetch(refreshTokenUrl, {
            data: { refresh_token: refreshToken },
          });
        } else {
          logout();
          return Promise.reject();
        }
      }
    } 

    return Promise.reject();
  }
,
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
});
